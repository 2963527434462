import new_logo from './new-logo.svg';
import './App.css';
import {PrimaryUUID, UuidLog} from "./uuid-container/uuid-container";
import {VersionOptions} from "./version-options/version-options";
import {FormatOptions} from "./format-options/format-options";
import {useEffect, useState} from "react";
import {uuidv7} from "uuidv7";
import {Uuid25} from "uuid25";
import {Link, useSearchParams} from "react-router-dom";
import {NIL as uuidEmpty, v1 as uuidv1, v4 as uuidv4} from "uuid";

const versions = {
    version7: {
        generator: uuidv7,
        description: "A Version 7 UUID is generated using a combination of time-based and random components."
    }, //version5: "",
    version4: {
        generator: uuidv4, description: "A Version 4 UUID is generated using random or pseudo-random values."
    }, //version3: "",
    version1: {
        generator: uuidv1,
        description: "A Version 1 UUID is generated using a combination of timestamp and MAC address (or node). A randomly generated node is used in this implementation for security.",
    }, empty: {
        generator: () => uuidEmpty,
        description: "An Empty UUID that contains all zeros. Used as a substitute for a real UUID."
    }
}

const formats = {
    hyphenated: {
        toFormat: (uuid) => uuid.toHyphenated()
    }, hex: {
        toFormat: (uuid) => uuid.toHex()
    }, braced: {
        toFormat: (uuid) => uuid.toBraced()
    }, urn: {
        toFormat: (uuid) => uuid.toUrn()
    }
}

function GeneratorApp() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [primaryUUID, setPrimaryUUID] = useState();
    const [uuidHistory, setUuidHistory] = useState([]);
    const [versionOption, setVersionOption] = useState((versions.hasOwnProperty(searchParams.get("version")) && searchParams.get("version")) || "version7");
    const [formatOption, setFormatOption] = useState((formats.hasOwnProperty(searchParams.get("format")) && searchParams.get("format")) || "hyphenated");

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        generateUUID();
        setSearchParams([["version", versionOption], ["format", formatOption]]);
    }, [versionOption, formatOption]);

    const generateUUID = () => {
        const versionGenerator = versions[versionOption].generator;
        let uuid = Uuid25.parse(versionGenerator());
        uuid = formats[formatOption].toFormat(uuid);

        let date = new Date();
        let time = date.toLocaleString([], {
            hour: '2-digit', minute: '2-digit', second: '2-digit'
        });
        const newUuid = {time, uuid};
        setUuidHistory([newUuid, ...uuidHistory]);
        setPrimaryUUID(uuid);
    }

    return (<div className="parent-frame">
            <img src={new_logo} className="header-logo" alt="UUIDGenerator.io"/>
            <div className="uuid-content">
                <PrimaryUUID uuid={primaryUUID} generateUUID={generateUUID}></PrimaryUUID>
                <div className="options-frame">
                    <VersionOptions versions={versions} versionOption={versionOption}
                                    setVersionOption={setVersionOption}/>
                    <FormatOptions formatOption={formatOption} setFormatOption={setFormatOption}/>
                </div>
                <div className="log-frame">
                    <UuidLog uuidHistory={uuidHistory}/>
                </div>
            </div>
            <div className="footer">
                <span className="copyright">Copyright © {new Date().getFullYear()} uuid-generator.io - All Rights Reserved</span>
                <span className="info-links">
                    <Link className="info-link" to="/about">About</Link>
                    <Link className="info-link" to="/disclaimer">Disclaimer</Link>
                    <Link className="info-link" to="/privacy-policy">Privacy Policy</Link>
                </span>
            </div>
        </div>);
}


export {GeneratorApp};
