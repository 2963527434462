import React from 'react';
import ReactDOM from 'react-dom/client';
import {GeneratorApp} from './App';
import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider} from "react-router-dom";
import {About, Disclaimer, PrivacyPolicy} from "./info-pages/InfoPages";


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path="" element={<Navigate to="/generator" />} />
            <Route path="*" element={<Navigate to="/generator" />} />
            <Route path="/generator" element={<GeneratorApp/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
            <Route path="/disclaimer" element={<Disclaimer/>}/>
            <Route path="/about" element={<About/>}/>
        </Route>
    )
) ;

ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router}/>
);


