import "./format-options.css"

export function FormatOptions({formatOption, setFormatOption}) {
    const onFormatSelected = (value) => {
        setFormatOption(value)
    }

    return (
        <div className="format-container">
            <h1 className="format-header">Format</h1>
            <div className="format-radio-container">
                <div className="format-radio-list">
                    <label className="format-label" htmlFor="hyphenated">
                        <input className="format-input" type="radio" id="hyphenated"
                               checked={formatOption === "hyphenated"}
                               onChange={() => onFormatSelected("hyphenated")}/>
                        Hyphenated
                    </label>
                    <label className="format-label" htmlFor="hex">
                        <input className="format-input" type="radio" id="hex"
                               checked={formatOption === "hex"}
                               onChange={() => onFormatSelected("hex")}/>
                        Hex
                    </label>
                </div>
                <div className="format-radio-list">
                    <label className="format-label" htmlFor="braced">
                        <input className="format-input" type="radio" id="braced"
                               checked={formatOption === "braced"}
                               onChange={() => onFormatSelected("braced")}/>
                        Braced
                    </label>
                    <label className="format-label" htmlFor="urn">
                        <input className="format-input" type="radio" id="urn"
                               checked={formatOption === "urn"}
                               onChange={() => onFormatSelected("urn")}/>
                        Urn
                    </label>
                </div>
            </div>
        </div>
    )
}