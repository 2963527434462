import "./version-options.css" ;

export function VersionOptions({versions, versionOption, setVersionOption}) {
    return (
        <div className="versions-container">
            <h1 className="version-header">Version</h1>
            <div className="versions-radio-container">
                <div className="versions-radio-list">
                    <label className="version-label" htmlFor="version7">
                        <input className="version-input" type="radio"
                               id="version7"
                               checked={versionOption === "version7"}
                               onChange={() => {
                                   setVersionOption("version7")
                               }}
                        />
                        Version 7
                    </label>

                    { /*
                        <label className="version-label" htmlFor="version5">
                            <input className="version-input" type="radio"
                                   id="version5"
                                   checked={versionOption === "version5"}
                                   onChange={() => {
                                       onVersionSelected("version5");
                                   }}
                            />
                            Version 5
                        </label>
                        */
                    }

                    <label className="version-label" htmlFor="version4">
                        <input className="version-input" type="radio"
                               id="version4"
                               checked={versionOption === "version4"}
                               onChange={() => {
                                   setVersionOption("version4");
                               }}
                        />
                        Version 4
                    </label>
                    { /*
                    <label className="version-label" htmlFor="version3">
                        <input className="version-input" type="radio"
                               id="version3"
                               checked={versionOption === "version3"}
                               onChange={() => {onVersionSelected("version3");}}
                        />
                        Version 3
                    </label>
                    */}
                </div>
                <div className="versions-radio-list">
                    <label className="version-label" htmlFor="version1">
                        <input className="version-input" type="radio"
                               id="version1"
                               checked={versionOption === "version1"}
                               onChange={() => {
                                   setVersionOption("version1");
                               }}
                        />
                        Version 1
                    </label>
                    <label className="version-label" htmlFor="empty">
                        <input className="version-input" type="radio"
                               id="empty"
                               checked={versionOption === "empty"}
                               onChange={() => {
                                   setVersionOption("empty");
                               }}
                        />
                        Empty
                    </label>
                </div>
            </div>
            <div className="version-description">{versions[versionOption].description}
            </div>
        </div>
    )
}