import './uuid-container.css';
import copyIcon from "../icons/copy-icon.svg";
import repeatIcon from "../icons/repeat-icon.svg";
import {useCallback, useState} from "react";

export function PrimaryUUID({uuid, generateUUID}) {
    const [copyButtonText, setCopyButtonText] = useState("Copy")

    const copyAction = useCallback(async () => {
        setCopyButtonText("Copied!");
        await navigator.clipboard.writeText(uuid)
        setTimeout(() => {
            setCopyButtonText("Copy");
        }, 1000);
    }, [uuid]);
    return (
        <div className="uuid-container">
            <span className="primary-uuid">{uuid}
            </span>
            <div className="button-container">
                <button className="icon-button-primary" onClick={copyAction}>
                    <img src={copyIcon} alt="copy"/>
                    <span className="button-text">{copyButtonText}</span>
                </button>

                <button className="icon-button-primary" onClick={generateUUID}>
                    <img src={repeatIcon} alt="regenerate"/>
                    <span className="button-text">Regenerate</span>
                </button>
            </div>
        </div>
    )
}


export function UuidLog({uuidHistory}) {

    return (
        <div className="log-container">
            <table>
                <thead>
                <tr>
                    <th>Time Generated</th>
                    <th>UUID</th>
                </tr>
                </thead>
                <tbody>
                {uuidHistory.map( history => (
                    <tr>
                        <td>{history.time}</td>
                        <td>{history.uuid}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}