import './info-pages.css';
import {Link} from "react-router-dom";

export function PrivacyPolicy() {

    return (
        <div className="info-page">
            <Link className="generator-link" to="/generator">&lt; Back to generator</Link>
            <h1>Privacy Policy</h1>

            <p>Thank you for visiting our website. This Privacy Policy outlines how we handle information collected from
                visitors to our static website.</p>

            <h2>Information Collection and Use</h2>

            <p>We do not collect any personal information from visitors to our website. Your privacy is important to us,
                and we do not engage in any practices that involve gathering or storing personally identifiable
                information.</p>

            <h2>Google Ads</h2>

            <p>Our website displays Google Ads. These ads are served by Google and are subject to Google's privacy
                policies. Google may use cookies to serve ads based on a user's prior visits to our website or other
                websites. You may opt out of personalized advertising by visiting Google's Ad Settings page.</p>

            <h2>Third-Party Links</h2>

            <p>Our website may contain links to third-party websites for your convenience or reference. We are not
                responsible for the privacy practices or the content of these websites. We encourage you to review the
                privacy policies of those websites before providing them with any personal information.</p>

            <h2>Changes to This Privacy Policy</h2>

            <p>We reserve the right to update or change our Privacy Policy at any time. Any changes will be posted on
                this page, and the effective date will be updated accordingly.</p>

            <br/>
            <p>By using our website, you consent to the terms of this Privacy Policy.</p>
        </div>
    )
}

export function Disclaimer() {

    return (
        <div className="info-page">
            <Link className="generator-link" to="/generator">&lt; Back to generator</Link>
            <h1>Privacy Policy</h1>
            <p>The UUIDs generated on this site are provided without any warranties, including the guarantee of
                uniqueness. You assume all risks associated with using these UUIDs.</p>
        </div>
    )
}

export function About() {
    return (
        <div className="info-page">
            <Link className="generator-link" to="/generator">&lt; Back to generator</Link>
            <h1>About</h1>
            <p>This site is used to generate UUIDs. To learn more about UUIDs see <a href="https://en.wikipedia.org/wiki/Universally_unique_identifier">Universally unique identifier</a></p>
        </div>
    )
}